/* src/pages/Services.css */
.services {
    padding: 40px;
    text-align: center;
    background-color: #fff;
    color: #333;
  }
  
  .services h2 {
    color: #ff9800;
    margin-bottom: 20px;
  }
  
  .services ul {
    list-style: none;
    padding: 0;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .services ul li {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .services ul li:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  