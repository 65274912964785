/* src/components/About.css */
.about-container {
  background: linear-gradient(135deg, #f9f9f9, #ffffff); /* Gradient background for a modern look */
  color: #333333; /* Dark grey text color for better readability */
  padding: 84px 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  overflow-x: hidden;
   /* Prevent horizontal overflow */
}

/* About Us Section Styling */
.about-us-section {
  text-align: center;
  margin-bottom: 60px;
  padding: 20px 40px;
  border-radius: 8px;
  animation: fadeIn 2s ease-out; /* Fade-in animation for the section */
  position: relative;
}

.about-us-heading {
  font-size: 36px;
  font-weight: bold;
  color: #FC6514; /* Primary brand color for header */
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: slideDown 1.5s ease-out; /* New animation for sliding down */
}

.about-us-text {
  font-size: 18px;
  color: #666666; /* Grey color for body text */
  max-width: 1200px;
  margin: 0 auto; /* Center-aligns the text block */
  line-height: 1.8;
  animation: fadeInText 2s ease-out; /* Smooth fade-in animation */
  text-align: left;
  text-align: justify;

}

/* General Section Styling with Hover Effects */
.section {
  display: flex;
  align-items: left;
  justify-content: space-between;
  margin-bottom: 60px;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; /* White background for each section */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
  position: relative; /* Needed for pseudo-elements */
  overflow: hidden; /* Hide overflow for animated borders */
}

.section:hover {
  transform: translateY(-10px); /* Slight lift effect on hover */
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

/* Animated Borders using Pseudo-elements */
.section::before, .section::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #FC6514; /* Brand color for borders */
  transition: width 0.5s ease;
}

.section::before {
  top: 0;
  left: -100%;
}

.section::after {
  bottom: 0;
  right: -100%;
}

.section:hover::before {
  left: 0;
}

.section:hover::after {
  right: 0;
}

/* Section Icons Styling */
.section-icon {
  flex: 0 0 60px;
  text-align: center;
  margin-right: 20px;
  animation: bounceIn 1s ease-out; /* New bounce animation */
}

/* Alternating layout for even sections */
.section:nth-child(even) {
  flex-direction: row-reverse;
  background-color: #f0f0f0; /* Slightly darker grey for alternating sections */
}

.section-text {
  flex: 1;
  padding: 0 20px;
  text-align: justify;
}

.section-text h2 {
  font-size: 28px;
  font-weight: bold;
  color: #FC6514; /* Primary brand color for headings */
  margin-bottom: 15px;
  animation: fadeInUp 1.5s ease-out; /* Animation for smooth appearance */
}

.section-text p {
  font-size: 16px;
  color: #666666; /* Grey color for body text */
  margin-bottom: 15px;
}

.section-image {
  flex: 1;
  text-align: center;
  animation: zoomIn 1.5s ease-out; /* Image animation for zoom effect */
}

.section-image img {
  max-width: 80%;
  height: auto;
  border-radius: 8px; /* Slight rounding of image corners */
}

/* Key People Section */
.key-people-section {
  background-color: #ffffff;
  margin-top: 40px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px; /* Sets a max width for the key people section */
  margin: auto; /* Center the key people section */
  text-align: center; /* Center-aligns the content */
  position: relative; /* To allow absolute positioning of the header */
}

/* Header Styling with Animation */
.key-people-section h2 {
  font-size: 36px;
  font-weight: bold;
  color: #FC6514; /* Primary brand color for header */
  margin-bottom: 30px;
  margin-top: 28px;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: fadeInUp 1.5s ease-out; /* Animation for smooth appearance */
  position: absolute;
  top: -40px; /* Adjust position to be at the top */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  padding: 10px 20px;
  z-index: 1; /* Ensure it is on top of other elements */
}

/* Key People Grid */
.key-people-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid layout */
  gap: 20px; /* Spacing between key people cards */
  width: 100%; /* Ensure grid takes full width of the parent container */
  margin-top: 50px; /* Space between header and grid */
}

/* Key People Card Styling */
.person {
  background-color: #f0f0f0; /* Light background for card */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s; /* Animation for hover effect */
}

.person:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.person h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #FC6514; /* Primary brand color for names */
}

.person p {
  font-size: 14px;
  color: #666666; /* Light grey for roles */
}

.person-image {
  width: 120px;
  height: 120px;
  border-radius: 50%; /* Makes the images round */
  object-fit: cover; /* Ensures images cover the circle area properly */
  margin-bottom: 10px;
}

.person-image-blank {
  width: 120px;
  height: 120px;
  background-color: #dddddd; /* Lighter grey for the blank image placeholder */
  border-radius: 50%;
  margin: 0 auto 10px;
}

/* Keyframe Animations */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
      transform: translateY(-30px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes fadeInText {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
      transform: scale(0.9);
  }
  40% {
      transform: scale(1.1);
  }
  60% {
      transform: scale(0.95);
  }
  80% {
      transform: scale(1.05);
  }
  100% {
      transform: scale(1);
  }
}

@keyframes zoomIn {
  from {
      transform: scale(0.8);
      opacity: 0;
  }
  to {
      transform: scale(1);
      opacity: 1;
  }
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .about-us-heading {
      font-size: 32px;
  }

  .about-us-text {
      font-size: 16px;
  }

  .section {
      flex-direction: column;
      text-align: center;
      padding: 30px 20px;
  }

  .section:nth-child(even) {
      flex-direction: column;
  }

  .section-icon {
      margin-bottom: 20px;
      margin-right: 0;
  }

  .section-text h2 {
      font-size: 24px;
  }

  .section-text p {
      font-size: 14px;
  }

  .section-image img {
      max-width: 100%;
  }

  .key-people-section h2 {
      font-size: 28px;
      top: -30px;
  }
}

@media (max-width: 768px) {
  .about-us-heading {
      font-size: 28px;
  }

  .about-us-text {
      font-size: 15px;
  }

  .section {
      padding: 20px 10px;
  }

  .section-text h2 {
      font-size: 22px;
  }

  .section-text p {
      font-size: 13px;
  }

  .key-people-section h2 {
      font-size: 24px;
      top: -25px;
  }

  .person {
      padding: 15px;
  }

  .person h3 {
      font-size: 16px;
  }

  .person p {
      font-size: 12px;
  }
}

@media (max-width: 480px) {
  .about-us-heading {
      font-size: 24px;
  }

  .about-us-text {
      font-size: 14px;
  }

  .section {
      padding: 15px 10px;
  }

  .section-text h2 {
      font-size: 20px;
  }

  .section-text p {
      font-size: 12px;
  }

  .section-icon {
      flex: 0 0 40px;
  }

  .key-people-section h2 {
      font-size: 20px;
      top: -20px;
  }

  .person h3 {
      font-size: 14px;
  }

  .person p {
      font-size: 11px;
  }

  .person-image {
      width: 100px;
      height: 100px;
  }
}
