/* src/components/Footer.css */
.footer-container {
    background-color: #1c1c1c;
    color: #ffffff;
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
  }
  
  .footer-section h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #ffffff;
  }
  
  .footer-section p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 15px;
    color: #d3d3d3;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: #d3d3d3;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s;
  }
  
  .footer-section ul li a:hover {
    color: #fc6514; /* Brand color on hover */
  }
  
  .social-connect {
    display: flex;
    flex-direction: column; /* Aligns content in a column */
    align-items: flex-start; /* Aligns text and icons to the left */
  }
  
  .social-connect h3 {
    margin-bottom: 10px; /* Adds space below the "Social Connect" heading */
  }
  
  .social-icons {
    display: flex; /* Sets flex display for icons */
    gap: 10px; /* Adds space between icons */
  }
  
  .social-icon {
    color: #ffffff;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .social-icon:hover {
    transform: scale(1.1); /* Slight zoom on hover for engagement */
  }
  
  .footer-bottom {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid #333;
    margin-top: 20px;
  }
  
  .footer-bottom p {
    font-size: 14px;
    color: #d3d3d3;
  }
  