.tnc-container {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .tnc-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .tnc-header h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .tnc-introduction {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .tnc-content {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  .tnc-content h2 {
    font-size: 1.8rem;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .tnc-content p {
    margin-bottom: 20px;
  }
  
  /* Style adjustments for better readability on mobile devices */
  @media (max-width: 768px) {
    .tnc-container {
      padding: 20px;
    }
  
    .tnc-header h1 {
      font-size: 2rem;
    }
  
    .tnc-content h2 {
      font-size: 1.5rem;
    }
  }
  