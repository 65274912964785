/* QRModal.css */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Fixed position to cover the viewport */
    z-index: 1000; /* High z-index to be on top of other content */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.modal-content {
    background-color: #fff;
    padding: 40px; /* Increase padding for more space */
    border-radius: 10px;
    text-align: center;
    position: relative;
    width: 400px; /* Adjust width as needed */
    height: auto; /* Allow height to be flexible */
    max-width: 90%; /* Ensure responsiveness */
}

  
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .qr-code {
    width: 200px;
    height: 200px;
    margin-top: 20px;
  }
  
  h2 {
    margin: 0;
    padding: 10px 0;
    font-size: 20px;
  }
  