 
/* Content Wrapper Style */
.main-content {
  margin-top: 80px; /* Matches the height of the navbar */
  padding: 20px; /* Adds some padding for spacing */
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
/* Global Styles (App.css or other global CSS) */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  overflow-y: auto; /* Allows vertical scrolling */
  box-sizing: border-box; /* Ensures consistent box-sizing across all elements */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
