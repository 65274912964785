/* General Styling */
.homepage {
  font-family: 'Poppins', sans-serif;
  padding: 0 20px;
  margin-top: 40px;
  width: 100%;
  position: relative;
}

/* Brand Message Styles */
.brand-message {
  text-align: left;
  margin: 20px 0;
  z-index: 3;
  position: relative;
  width: 100%;
  font-size: 2rem;
  color: #888888;
  font-weight: 600;
  animation: fadeIn 2s forwards;
  padding: 10px 0;
  padding-left: 10%;
}

/* Hero Section */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
  background-color: #f3f3f3;
  flex-wrap: wrap;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
}

.hero-content {
  max-width: 50%;
  text-align: center;
  flex: 1;
  position: relative;
  z-index: 2;
  padding-bottom: 10px;
}

.hero-content h1 {
  font-size: 3rem;
  color: #FC6514;
  margin-bottom: 40px;
  animation: fadeIn 1.5s ease-out;
  padding-left: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
}

.hero-content p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
  animation: fadeIn 2s ease-out;
}

.hero-button {
  background-color: #FC6514;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.hero-button:hover {
  background-color: #e55b12;
  transform: scale(1.1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* iPhone Frame Styles */
.iphone-frame {
  width: 375px;
  height: 667px;
  border: 16px solid #333;
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slideIn 1.5s ease-out;
}

.iphone-notch {
  width: 210px;
  height: 30px;
  background-color: #333;
  border-radius: 0 0 20px 20px;
  position: absolute;
  top: -16px;
  left: calc(50% - 105px);
  z-index: 3;
}

.iphone-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1;
}

.iphone-full-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Overview Section */
.overview {
  text-align: left;
  padding: 40px 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1.5s ease-out;
}

.overview h1 {
  font-size: 2.5rem;
  color: #d17f33;
  text-align: left;
  margin-bottom: 20px;
  padding-left: 115px;
  text-align: justify;
}

.overview p {
  font-size: 1.1rem;
  color: #666;
  margin: 0 auto;
  max-width: 1000px;
  text-align: justify;
}

.key-actors {
  text-align: center;
  padding: 60px 0;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1.5s ease-out;
  position: relative;
  overflow: hidden;
}

.key-actors h2 {
  font-size: 2.5rem;
  color: #FC6514;
  margin-bottom: 40px;
}

.actors-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive grid with minimum width */
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

.actor-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  animation: scaleIn 0.5s ease-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.actor-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.actor-card img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.actor-card h3 {
  color: #333;
  margin: 0;
  font-size: x-small;
  word-break: break-word; /* Ensures long words break correctly */
  overflow-wrap: break-word; /* Supports wrapping for longer words */
  white-space: normal; /* Allows text to wrap */
  line-height: 1.4; /* Improved line height for readability */
  display: inline-block; /* Ensures text takes full width */
}

.actor-card p {
  color: #666;
  font-size: 0.9rem;
  margin-top: 10px;
  word-wrap: break-word;
  max-width: 100%;
  line-height: 1.4;
}

/* Ensure Text Doesn't Truncate */
.actor-card h3 {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
}

/* Our Services Section */
.our-services {
  text-align: center;
  padding: 60px 0;
  background-color: #e9ecef;
  border-top: 5px solid #e87816;
  
}

.our-services h2 {
  font-size: 2.8rem;
  color: #d17f33;
  margin-bottom: 40px;
  animation: fadeInUp 1.5s ease-out;
}

.services-container {
  display: flex;
  flex-wrap: wrap; /* Ensures row layout by default */
  justify-content: space-around;
  animation: fadeInUp 1.5s ease-out;
  gap: 20px;
}

.service-card {
  background-color: #f8f9fa;
  border-radius: 5px;
  flex: 1 1 calc(33.33% - 20px); /* Three cards in a row by default */
  margin: 10px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 300px;
  
}

.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.service-card img {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.service-card h3 {
  color: #5095db;
  margin-bottom: 10px;
  font-size: 1rem;
}

.service-card p {
  color: #6c757d;
  font-size: 1rem;
  text-align: justify;
}

/* Responsive Adjustments for Smaller Devices */
@media (max-width: 768px) {
  /* Hero Section Adjustments */
  .hero {
    padding: 30px 10px;
    flex-direction: column;
    align-items: center;
  }

  .hero-content {
    max-width: 100%;
    padding-bottom: 0;
  }

  .hero-content h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    padding-left: 0;
    white-space: normal;
    word-break: break-word;
  }

  .hero-content p {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .hero-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .iphone-frame {
    width: 220px;
    height: 400px;
    animation: none;
  }

  /* Overview Section Adjustments */
  .overview h1 {
    font-size: 1.8rem;
    padding-left: 0;
    text-align: center;
  }

  .overview p {
    font-size: 1rem;
    padding: 0 20px;
    max-width: 100%;
  }

  /* Key Actors Section Adjustments */
  .key-actors h2 {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }

  .actors-container {
    flex-direction: column; /* Single column layout for smaller screens */
  }

  .actor-card {
    width: 100%;
    margin: 10px auto;
    animation: none;
  }

  .actor-card img {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }

  /* Our Services Section Adjustments */
  .services-container {
    flex-direction: column; /* Single column layout for smaller screens */
  }

  .service-card {
    width: 100%;
    max-width: 280px;
    margin: 8px auto;
  }
}

/* Devices with a maximum width of 576px */
@media (max-width: 576px) {
  .hero {
    padding: 20px 10px;
  }

  .brand-message {
    font-size: 1.5rem;
    color: #888888;
  }

  .hero-content h1 {
    font-size: 1.1 rem;
    margin-bottom: 10px;
    white-space: normal;
  }

  .hero-content p {
    font-size: 0.8rem;
  }

  .hero-button {
    padding: 6px 12px;
    font-size: 0.75rem;
  }

  

  .iphone-frame {
    width: 200px;
    height: 360px;
    animation: none;
  }

  .key-actors {
    padding: 20px 5px;
  }

  .key-actors h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .actors-container {
    flex-direction: column;
    gap: 15px;
  }

  .actor-card {
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
  }

  .actor-card img {
    width: 60px;
    height: 60px;
  }

  .actor-card h3 {
    font-size: 0.9rem;
  }

  .actor-card p {
    font-size: 0.8rem;
  }

  /* Our Services Section Adjustments */
  .services-container {
    flex-direction: column;
  }

  .service-card {
    width: 100%;
    max-width: 260px;
    margin: 8px auto;
    padding: 15px 10px;
  }
}

/* Devices with a maximum width of 480px */
@media (max-width: 480px) {
  .hero {
    padding: 15px;
  }

  .brand-message {
    font-size: 1.3rem;
    color: #888888;
    padding-left: 5%;
  }

  .hero-content h1 {
    font-size: 1.2rem;
    margin-bottom: 5px;
    white-space: normal;
  }

  .hero-content p {
    font-size: 0.7rem;
  }

  .hero-button {
    padding: 5px 10px;
    font-size: 0.7rem;
  }

  .iphone-frame {
    width: 180px;
    height: 320px;
    animation: none;
  }

  .key-actors {
    padding: 15px 5px;
  }

  .key-actors h2 {
    font-size: 1.3rem;
  }

  .actors-container {
    flex-direction: column;
    gap: 10px;
  }

  .actor-card {
    width: 100%;
    margin: 8px 0;
  }

  .actor-card img {
    width: 50px;
    height: 50px;
  }

  .actor-card h3 {
    font-size: 0.8rem;
  }

  .actor-card p {
    font-size: 0.7rem;
  }

  /* Our Services Section Adjustments */
  .services-container {
    flex-direction: column;
    gap: 8px;
  }

  .service-card {
    width: 100%;
    max-width: 240px;
    margin: 6px auto;
    padding: 12px 8px;
  }
  .qr-code-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: 999; /* Ensure it's above other elements */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
}

@media only screen and (max-width: 768px) {
    .qr-code-modal {
        width: 80%;
        left: 10%;
        transform: translateY(-50%);
    }
}


}